.footer {
  color: rgb(253, 251, 251);
  text-align: center;
  position: absolute;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  z-index: 5;
}
.footerWave {
  background: none;
  width: 100%;
  position: relative;

  z-index: 1;
}
