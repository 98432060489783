.contact {
  background-color: #77aa77;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  color: rgb(255, 255, 255);
}

.msg-form {
  background-color: white;
  padding: 20px;
}
.contact {
  color: black;
}
.pad-icon {
  padding-top: 50px;
  padding-left: 20px;
}

.pad-icon a {
  text-decoration: none;
  margin-right: 40px;
}

.input-group input:focus {
  border: 1px solid blue;
}

.pad-icon a:active {
  height: 30px;
  width: 30px;
  background-color: #0080ff;
  border-radius: 100%;
}
.btn {
  color: #fff;
}
.links {
  padding-top: 50px;
  width: 50%;
}

#bordering a:active {
  border: 1px solid #0080ff;
}

@media (min-width: 568px) {
  .contact {
    margin: 10px 10px;
    width: 96%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 567px) {
  .contact {
    margin: 10px 10px;
    width: 94%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pad {
    padding-top: 20px;
  }
}
