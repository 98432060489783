.blueOuterBackground {
  background-color: transparent;
  background-image: linear-gradient(280deg, #000000 0%, #001696 100%);
}
.blueInnerSection {
  opacity: 0.9;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  background-image: url(https://zeptosystems.com/wp-content/uploads/2019/10/laptop-1.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto;
}

.blueLeftSection {
  flex: 0.8;
}
.blueRightSection {
  flex: 0.2;
}
.itStaticBackground {
  background-image: url(https://zeptosystems.com/wp-content/uploads/2019/10/mobile2.png);
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 33% auto;
  top: 200;
}
.itBackInnerSection {
  background-color: #ebe6e6;
  opacity: 0.6;
}
.itSection {
  flex: 0.7;
  color: #000000;
}
.itContent {
  display: flex;
  flex-wrap: wrap;
}
.itContent ul {
  margin-right: 10%;
  margin-topt: 10%;
}
