$buttonColor: #fd1313;
$borderColor: rgb(255, 254, 254);

.teamCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardDivider {
  background-color: $buttonColor;
  padding-top: 2px;
  padding-bottom: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 5rem;
}

.cardAvatar {
  border: 4px solid $borderColor;
  border-radius: 50%;
  width: 35%;
  align-self: center;
  margin: 10px;
  padding: 3px;
}
.cardAvatar3 {
  border: 4px solid $borderColor;
  border-radius: 50%;
  width: 50%;
  align-self: center;
  margin: 10px;
  padding: 3px;
}
.cofounderCard .card {
  color: #fff;
  background: #1a2980; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #26d0ce,
    #1a2980
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #26d0ce,
    #1a2980
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.founderCard .card {
  background-image: linear-gradient(135deg, #3c8ce7 10%, #00eaff 100%);
  color: #fff;
}
// .founderCard .card-body p {
//   color: #fd1313;
// }
.cofounderCard {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}
.founderCard {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}
.navbar-toggler {
}
.navbar-toggler-icon {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
}
.card {
  margin-top: 4%;
  flex: 0.5;
  border: none;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardText2 {
  color: #fd1313;
}
@media screen and (max-width: 760px) {
  .cardAvatar {
    width: 45%;
  }
  .cardAvatar3 {
    width: 50%;
  }
}
@media screen and (max-width: 580px) {
  .cardAvatar {
    width: 55%;
  }
  .cardAvatar3 {
    width: 60%;
  }
}

@media screen and (max-width: 575px) {
  .card {
    flex: 1;
    width: 100%;
  }
  .cardAvatar {
    width: 40%;
  }
  .cardAvatar3 {
    width: 35%;
  }
  .mainSoft {
    padding-left: 1%;
    padding-right: 1%;
  }
  .founderCard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
  .cofounderCard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (min-width: 575px) {
  .card {
    margin: 2%;
  }
}
// wok in progress
.workProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-top: 9%;
    width: 20%;
    margin-bottom: 50%;
  }
  @media screen and (max-width: 575px) {
    img {
      margin-top: 30%;
      margin-bottom: 50%;
      width: 50%;
    }
  }
}
@media screen and (max-width: 815px) {
  h3 {
    font-size: 18px;
  }
}
