/* TOP HEADER */
.topHeader {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}
.dropdown-toggle {
  cursor: pointer;
}
.bunRoute {
  border-radius: 10px;
  padding: 10px;
}
Link Button:hover {
  text-decoration: none;
}
/* NAV BAR */
.navbar-light .navbar-nav .nav-link.active {
  color: rgb(53, 52, 52);
  font-weight: bold;
}

.navbarHeader {
  min-height: 76px;
  margin-bottom: 0;
  background-color: #fff;
  border: 0;
  top: 0;
  box-shadow: 0 -1px 15px 0 rgb(200 219 239 / 70%);
}
.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 1.5rem;
}
.dropdown-menu {
  background: #fafcfa; /* fallback for old browsers */

  transform-origin: top;
  z-index: 1000;
  padding: 0;
  border: none;
  left: 0;
  transition: all 6100ms ease-in-out 0;
  box-shadow: 0 -1px 15px 0 rgb(200 219 239 / 70%);
  border-radius: 6px;
  overflow: hidden;
  margin-top: -5px;
}
.dropdown-item {
  color: #1b1b1b;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 60px;
  border-bottom: 1px solid rgb(235, 233, 233);
}
.dropdown-item:hover {
  color: rgb(53, 52, 52);
  font-weight: bold;
}
a img {
  width: 158px;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: rgb(53, 52, 52);
  font-weight: bold;
}
.navbar-light .navbar-nav .nav-link {
  /* line-height: 95px; */

  background-image: none;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  color: rgb(53, 52, 52);
  text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(53, 52, 52);
  font-weight: bold;
}
.navbar-light .navbar-nav .nav-link:active {
  color: #fefeff;
}
@media screen and (max-width: 1027px) {
  a img {
    width: 140px;
  }
}
@media screen and (max-width: 1005px) {
  a img {
    width: 120px;
  }
}
@media screen and (max-width: 982px) {
  a img {
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
  a img {
    width: 140px;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    padding-right: 5%;
    padding-left: 5%;
  }
}
