.section1 {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  flex-wrap: wrap;
}
.leftSection {
  flex: 0.5;
}
.myButton {
  background-color: #ff248a;
  border-radius: 6px;
  color: #fff;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.rightSection1 {
  align-content: center;

  flex: 0.5;
}
.rightSection2 {
  align-content: center;

  flex: 0.5;
}
.rightSection1 img {
  margin-left: 30%;
  margin-right: 10%;
}
.divider {
  background-color: #ff248a;
  padding-top: 2px;
  padding-bottom: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 52%;
}

@media screen and (max-width: 768px) {
  .rightSection1 img {
    margin-left: -1%;
  }
  .rightSection2 img {
    margin-left: 1%;
  }

  .section1 {
    flex-direction: column;
  }
}
