/* circular animation */
@media screen and (max-width: 767px) {
  .green_span {
    top: -4%;
  }
}
@media screen and (max-width: 805px) {
  h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 805px) {
  h2 {
    font-size: 20px;
  }
}
