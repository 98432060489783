* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.mainPage {
  margin-top: 80px;
}
.myApp {
  background-color: #ffffff;
}
.bottom-chatt {
  position: fixed;

  bottom: 2%;
  left: 10px;

  width: 100px;
  z-index: 10;
}
.bottom-chatt img {
  width: 50px;
}
.pageNotFound {
  display: flex;
  justify-content: center;
}
.pageNotFound img {
  margin-top: 40px;
  width: 50%;
}
