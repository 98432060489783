.socialIcons {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  position: fixed;
  border-radius: 10px;
  right: 20px;
  z-index: 200;
  bottom: 15%;
}
.icons {
  cursor: pointer;

  padding: 8px;
}
